import React from 'react';
import c from './style.module.scss';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';
import Close from '../svg/close';

const Form = ({ type }) => { 
  const { setModal } = useContext(ModalContext);

  return (
    <div className={c.container}>
      <div className={c.close}>
        <span onClick={() => setModal(null)}>
          <Close />
        </span>
      </div>
      <form className={c.form} method="POST" id="form">
        <div className={c.inputContainer}>
          <input
            required
            type="text"
            minLength="2"
            maxLength="20"
            name="name"
            id="name"
            placeholder="How should we address you"
          />
        </div>
        <div className={c.inputContainer}>
          <input
            className={c.delivery}
            required
            type="tel"
            maxLength="13"
            name="phone"
            id="phone"
            placeholder="Phone number"
          />
          <p className={c.required}>Necessarily</p>
        </div>
        {type === "b" && (
          <>
            <div className={c.inputContainer}>
              <input
                type="text"
                minLength="2"
                maxLength="40"
                data-error=""
                name="from"
                id="from"
                placeholder={`Delivery from`}
              />
            </div>
            <div className={c.inputContainer}>
              <input
                type="text"
                minLength="2"
                maxLength="40"
                data-error=""
                name="to"
                id="to"
                placeholder={`Delivery to where`}
              />
            </div>
            <div className={c.inputContainer}>
              <input
                type="text"
                minLength="2"
                maxLength="40"
                data-error=""
                name="what"
                id="what"
                placeholder={`What needs to be delivered?`}
              />
            </div>
          </>
        )}
        <div className={c.inputContainer}>
          <textarea
            maxLength="200"
            data-error=""
            name="comment"
            id="comment"
            placeholder={`Comment`}
          ></textarea>
        </div>
        <input
          className={c.btn}
          type="submit"
          name="send"
          id="submit"
          value={`Submit an application`}
        />
      </form>
    </div>
  );
};

export default Form;