import React from 'react';
import Layout from '../layout';
import Button from '../button';
import Calculator from './calculator';
import c from './style.module.scss';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';

const Logistics = () => { 
  const { setModal } = useContext(ModalContext);

  return (
    <div id="calculation" className={c.calculation}>
      <Layout>
        <div className={c.container}>
          <div className={c.left}>
            <p className={c.title}>What is the customs clearance price</p>
            <p className={c.subtitle}>
              Calculate the customs clearance price for your vehicle
            </p>
            <Button
              onClick={() => setModal("b")}
              variant="secondary"
              text="Obtain the total cost with delivery"
            />
          </div>
          <div className={c.right}>
            <Calculator />
          </div>
        </div>
      </Layout>
    </div>
  ); 
};

export default Logistics;