export const sliderData = [
  {
    title: "Efficient Operations",
    dek: "High operational speed and quick vehicle transportation by car carriers. Once the container arrives in Lithuania and is processed, it is dispatched to the client. Our customers save on storage fees, and document processing is quick and efficient. Tow trucks frequently travel between countries, allowing cars to be transported from Klaipeda port to Ukraine in just a couple of weeks",
  },
  {
    title: "Qualified Personnel",
    dek: "Only verified personnel. Our car carriers are operated solely by drivers with extensive experience. You can be assured that even with oversized, expensive transport, everything will be perfectly managed.",
  },
  {
    title: "Multiple Payment Options",
    dek: "Flexible payment options. When signing a transportation contract, we offer several payment methods. Corporate clients and entrepreneurs can conveniently make payments through bank transfers. Additionally, customers can pay for transportation and logistic services using credit cards from major banks.",
  },
  {
    title: "Cost-Effective Solutions",
    dek: "Discounts and promotions. One car carrier can hold up to 8 sedan cars. This efficiency means the customer does not overpay for vehicle delivery and can enjoy additional savings. Discounts are often available for transporting goods from the Klaipeda port to Dnipro. Provide your contact details, and we will tailor a towing transportation solution to fit your budget and preferences.",
  },
  {
    title: "Fast Delivery",
    dek: `Short delivery times for car carrier lots. Routes from Lithuania to Ukraine are optimized to save customers' time and money. These routes are efficient, allowing cars from America to be delivered very quickly. Buyers do not have to wait months for their vehicles to arrive in our country, saving significant time.`,
  },
  {
    title: "Comprehensive Insurance and Documentation",
    dek: "Complete documentation package. All cargo is automatically insured, not only during sea transportation but also while on the road from Lithuania. Our partners also provide the necessary papers for customs clearance. Clients are protected against any adverse situations and will not lose their money even in the rare case of the tow truck being involved in an accident. Furthermore, registering the vehicle in Ukraine will not consume much time.",
  },
];