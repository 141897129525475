import React from 'react';
import Location from '../svg/location';
import ArrowDirection from '../svg/arrow-direction';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';
import Layout from '../layout';
import c from './style.module.scss';

const Heading = () => {
  const { setModal } = useContext(ModalContext);

	return (
		<Layout> 
			<div className={c.heading}>
				<h1>
					Delivering Your Vehicle
					<br />
					<span>Worldwide</span>
				</h1>
				<div onClick={() => setModal('a')} className={c.application}>
					<div className={c.enter}>
						<Location />
						<p>Enter pickup location</p>
					</div>
					<div className={c.enter}>
						<Location />
						<p>Enter destination location</p>
					</div>
					<div className={c.btn}>
						<ArrowDirection />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Heading;