import React, { useState } from "react";
import Calendar from "../../svg/calendar";
import Currency from "../../svg/currency";
import Engine from "../../svg/engine";
import CalculatorSvg from "../../svg/calculator";
import Arrow from "../../svg/arrow";
import c from "./style.module.scss";

const engineTypes = [
  {name: 'Gasoline', value: 'petrol'},
  {name: 'Diesel', value: 'diesel'},
  {name: 'Electric', value: 'electro'},
  {name: 'Hybrid', value: 'hybrid'},
];

function calcMyto(price, motorType = '') {
  const pMyto = 0.1;

  if (motorType === 'electro') return 0;
  return Math.round(price * pMyto);
}

function calcActsyz(year, motorType, capacity) {
  const cYear = new Date().getFullYear() - year - 1;
  let kYear = cYear;
  if (cYear > 15) kYear = 15;
  if (cYear < 1) kYear = 1;

  const kCapacity = capacity / 1000;

  if (motorType === 'electro') return Math.round(convertEuroToUsd(capacity));
  if (motorType === 'hybrid') return Math.round(convertEuroToUsd(100));

  return Math.round(findStavka(motorType, capacity) * kYear * kCapacity);
}

function calcPDV(price, myto, actsyz, motorType = '') {
  const pdv = 0.2;

  if (motorType === 'electro') return 0;
  return Math.round((+price + convertEuroToUsd(actsyz) + convertEuroToUsd(myto)) * pdv);
}

function findStavka(motorType, capacity) {
  const a = [
    {
      type: 'petrol',
      capacity: 3000,
      rate: 50
    },
    {
      type: 'diesel',
      capacity: 3500,
      rate: 75
    }
  ];

  const record = a.find(({ type }) => type === motorType);
  if (capacity <= record.capacity) return record.rate;
  return record.rate * 2;
}

function convertEuroToUsd(n) {
  const rate = 1.0843;
  return n * rate;
}

const Calculator = () => {
  const [price, setPrice] = useState('');
  const [year, setYear] = useState('');
  const [engine, setEngine] = useState(engineTypes[0].value);
  const [capacity, setCapacity] = useState('');

  const [error, setError] = useState({});

  const [duty, setDuty] = useState(0);
  const [tax, setTax] = useState(0);
  const [vat, setVat] = useState(0);


  const [totalResult, setTotalResult] = useState(0);

  const onPriceChange = (e) => {
    const value = +(e.target.value);
    if(typeof value === 'number' && !isNaN(value)) { 
      setPrice((e.target.value));
    }
  };

  const onYearChange = (e) => {
    const value = +(e.target.value);
    if(typeof value === 'number' && !isNaN(value)) { 
      setYear(e.target.value);
    }
  };

  const onEngineChange = (e) => { 
    setEngine(e.target.value);
  };

  const onCapacityChange = (e) => {
    const value = +(e.target.value);
    if(typeof value === 'number' && !isNaN(value)) {
      setCapacity(e.target.value);
    }
   }

  const onCalculate = () => {
    setError({});

    const error = {};

    if(price === '') {
      error.price = 'Price is required';
    } 
    if(year === '') {
      error.year = 'Year is required';
      // setError({...error, year: 'Year is required'});
    }
    if(capacity === '') {
      error.capacity = 'Capacity is required';
    }

    if(Object.keys(error).length > 0) {
      setError(error);
      return;
    };

    const myto = calcMyto(price, engine);
    setDuty(myto);
    const actsyz = calcActsyz(year, engine, capacity);
    setTax(actsyz);
    const pdv = calcPDV(price, myto, actsyz, engine);
    setVat(pdv);

    setTotalResult(+price + +myto + +actsyz + +pdv);
  };


  return (
    <div className={c.calculator}>
      <div className={c.inputs}>
        <div className={c.inputSection}>
          <input
            onChange={onPriceChange}
            type="number"
            placeholder="Vehicle Price"
          />
          {error.price && <p className={c.error}>{error.price}</p>}
          <Currency />
        </div>
        <div className={c.inputSection}>
          <input
            onChange={onYearChange}
            type="number"
            min="1970"
            max="2024"
            step="1"
            placeholder="Vehicle Year"
          />
          {error.year && <p className={c.error}>{error.year}</p>}
          <Calendar />
        </div>
        <div className={c.pair}>
          <div className={c.inputSection}>
            <select onChange={onEngineChange} value={engine}>
              {engineTypes.map((fuelType) => (
                <option key={fuelType.value} value={fuelType.value}>
                  {fuelType.name}
                </option>
              ))}
            </select>
            <Engine />
            <Arrow />
          </div>
          <div className={c.inputSection}>
            <input
              min={1000}
              onChange={onCapacityChange}
              type="number"
              placeholder="Engine Capacity"
            />
            <Engine />
            <p>Engine capacity (cc) / Battery capacity (kW)</p>
            {error.capacity && <p className={c.error}>{error.capacity}</p>}
          </div>
        </div>
      </div>
      <div onClick={() => onCalculate()} className={c.calculation}>
        <div>
          <CalculatorSvg />
          <span>Calculate</span>
        </div>
      </div>
      <div className={c.tax}>
        <div>
          <p className={c.name}>Duty</p>
          <p className={c.value}>{duty}$</p>
        </div>
        <div>
          <p className={c.name}>Tax</p>
          <p className={c.value}>{tax}$</p>
        </div>
        <div>
          <p className={c.name}>VAT</p>
          <p className={c.value}>{vat}$</p>
        </div>
      </div>
      <div className={c.total}>
        <p>Price of a customs-cleared car</p>
        <span>{totalResult}$</span>
      </div>
    </div>
  );
};

export default Calculator;