const Picture = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9852 24.2771C20.9852 21.9109 22.9033 19.9928 25.2694 19.9928C27.6356 19.9928 29.5536 21.9109 29.5536 24.2771C29.5536 26.6432 27.6355 28.5613 25.2694 28.5613C22.9032 28.5613 20.9852 26.6431 20.9852 24.2771ZM23.0253 24.277C23.0253 25.5164 24.03 26.5211 25.2694 26.5211C26.5088 26.5211 27.5135 25.5164 27.5135 24.277C27.5135 23.0375 26.5088 22.0328 25.2694 22.0328C24.03 22.0328 23.0253 23.0375 23.0253 24.277Z"
        fill="#505050"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.508 7.0381L42.3553 10.2002C44.6015 10.4668 46.2157 12.4902 45.9765 14.7395L43.6304 35.4465C43.5068 36.5196 42.9777 37.5049 42.1513 38.2006C41.569 38.6043 40.909 38.8822 40.2132 39.0166V39.8327C40.2132 42.0768 38.1731 43.8109 35.929 43.8109H7.92862C7.87707 43.8115 7.82543 43.8112 7.77388 43.8099C5.63381 43.7534 3.94486 41.9726 4.00138 39.8327V18.9727C4.00138 16.7286 5.68448 14.7395 7.92862 14.7395H9.5098L10.0198 10.5573C10.1316 9.50891 10.6656 8.55124 11.4988 7.90518C12.3322 7.19996 13.4272 6.88444 14.508 7.0381ZM35.929 41.7708C37.051 41.7708 38.1731 40.9547 38.1731 39.8327L38.1732 29.9382L28.1257 35.7525C27.387 36.1965 26.5394 36.426 25.6776 36.4155C24.5753 36.3985 23.5126 36.0023 22.6684 35.2934L17.1091 30.3972C16.2342 29.5799 14.8966 29.5151 13.9469 30.2442L6.04148 36.0585V39.8327C6.03855 39.8849 6.03787 39.9371 6.03943 39.9893C6.06921 41.003 6.91501 41.8005 7.92862 41.7708H35.929ZM27.0545 34.0184L38.1731 27.5411V18.9727C38.0959 17.7754 37.1278 16.8293 35.929 16.7796H7.92862C6.80655 16.7796 6.04148 17.8506 6.04148 18.9727V33.5083L12.7738 28.6121C14.477 27.3497 16.8272 27.4345 18.4351 28.8161L24.0453 33.7634C24.8913 34.4816 26.0998 34.584 27.0545 34.0184ZM43.9377 14.4711C43.9379 14.4687 43.9381 14.4664 43.9384 14.4641C44.0449 13.3424 43.2219 12.3468 42.1004 12.2403L14.304 9.02715C13.762 8.98175 13.2212 9.12594 12.7739 9.43521C12.3777 9.77933 12.1084 10.2462 12.0088 10.7613L11.5498 14.7395H35.9289C38.2531 14.7921 40.1326 16.6492 40.2131 18.9727V36.9765C40.2131 36.9266 40.3229 36.8767 40.459 36.8149C40.6011 36.7503 40.7719 36.6727 40.8761 36.5685C41.2985 36.2369 41.5433 35.7283 41.5392 35.1914L43.9363 14.4844C43.9368 14.48 43.9373 14.4755 43.9377 14.4711Z"
        fill="#505050"
      />
    </svg>
  );
}

export default Picture;