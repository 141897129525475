import React from 'react';
import cn from 'classnames';
import c from './style.module.scss';

const Button = ({text, variant = 'primary', className = '', onClick = () => {}}) => { 
	return (
		<button onClick={onClick} className={cn(c.button, c[variant], className)}>
			{text}
		</button>
	)
}

export default Button;
