import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import Layout from '../layout';
import ArrowDirection from '../svg/arrow-direction';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Parallax, Pagination, Navigation } from 'swiper/modules';
import { sliderData } from './sliderData';
// import d from '../../../public/images/port-bg.png'
// Import Swiper styles
import 'swiper/css';

import c from './style.module.scss';
// Slider

const Benefits = () => { 
  const swiperRef = useRef(null);
  const [isEnd, setIsEnd] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  console.log(activeIndex)

  useEffect(() => {
    console.log(swiperRef.current)
  }, []);

  const slideNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const slidePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const slideToIndex = (index) => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(index);
    }
  };

  const handleInit = (swiper) => {
    swiperRef.current = swiper;
  };

  return (
    <div id="benefits" className={c.benefits}>
      <Layout>
        <div className={c.container}>
          <div className={c.left}>
            <p>
              Why
              <br />
              Choose Us
            </p>
          </div>
          <div className={c.right}>
            <p>
              Choose us for rapid vehicle transport with experienced handlers,
              multiple payment options, and guaranteed safety. Save time and
              money on every delivery, backed by comprehensive insurance and
              efficient logistics
            </p>
            <div className={c.navSlider}>
              <button
                disabled={!isEnd}
                className={cn(c.button, c.prev, { [c.disabled]: !isEnd })}
                onClick={() => slidePrev()}
              >
                <ArrowDirection />
              </button>
              <button
                disabled={isEnd}
                className={cn(c.button, {
                  [c.disabled]: isEnd,
                })}
                onClick={() => slideNext()}
              >
                <ArrowDirection />
              </button>
            </div>
          </div>
        </div>
      </Layout>
      <Layout innerClassName={c.layoutOverride}>
        <Swiper
          onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
          onReachEnd={() => setIsEnd(true)}
          onReachBeginning={() => setIsEnd(false)}
          onSwiper={handleInit}
          ref={swiperRef}
          style={{
            "--swiper-navigation-color": "#fff",
            "--swiper-pagination-color": "#fff",
          }}
          breakpoints={{
            0: {
              slidesPerView: 1,
              slidesPerGroup: 1,
            },
            840: {
              slidesPerView: 3,
              slidesPerGroup: 6,
            },
          }}
          allowSlideNext
          clas
          speed={400}
          parallax={true}
          modules={[Parallax, Pagination, Navigation]}
          className={c.swiper}
        >
          <div
            slot="container-start"
            className={cn(c.parallaxBg, { [c.end]: isEnd })}
            data-swiper-parallax="-23%"
          ></div>
          {sliderData.map(({ title, dek }, index) => {
            return (
              <div>
                <SwiperSlide virtualIndex={index} className={c.slide}>
                  <div className={c.content}>
                    <p className={c.num}>{`0${index + 1}`}</p>
                    <p className={c.title}>{title}</p>
                    <p className={c.dek}>{dek}</p>
                  </div>
                  <div className={c.showMore}>
                    <div className={c.btn}>
                      <ArrowDirection />
                    </div>
                    <p className={c.text}>
                      <span>Show</span> <span className={c.more}>More</span>
                      <span className={c.less}>Less</span>
                    </p>
                  </div>
                </SwiperSlide>
              </div>
            );
          })}
        </Swiper>
      </Layout>
      <div className={c.mobileNav}>
        {Array(sliderData.length)
          .fill(0)
          .map((_, index) => {
            return (
              <div
                onClick={() => slideToIndex(index)}
                className={cn(c.bullet, { [c.active]: index === activeIndex })}
              ></div>
            );
          })}
          <div onClick={slidePrev} className={c.arrowLeft}>
            <ArrowDirection />
          </div>
          <div onClick={slideNext} className={c.arrowRight}>
            <ArrowDirection />
          </div>
      </div>
    </div>
  );
};

export default Benefits;