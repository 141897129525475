import React from 'react';
import Layout from '../layout';
import Button from '../button';

import c from './style.module.scss';

const AboutUs = () => {

  return (
    <div id="about" className={c.aboutUs}>
      <Layout>
        <div className={c.content}>
          <div className={c.left}>
            <p className={c.heading}>Who we are</p>
            <p className={c.dek}>
              We are a logistics company focused on providing reliable and
              efficient vehicle transport services. Our team is dedicated to
              ensuring secure, prompt delivery with professional handling every
              step of the way. Choose us for expert-driven logistics solutions
              that save time and reduce costs.
            </p>
            <a href="mailto:contact@intercardelivery.com">
              <Button variant='tetriary' text="Contact us" />
            </a>
          </div>
          <div className={c.right}>
            <div>
              <p className={c.number}>98%</p>
              <p className={c.text}>Customer Satisfaction</p>
            </div>
            <div>
              <p className={c.number}>420+</p>
              <p className={c.text}>Regular customers</p>
            </div>
            <div>
              <p className={c.number}>24/7</p>
              <p className={c.text}>Round-the-CLock Support</p>
            </div>
            <div>
              <p className={c.number}>4800+</p>
              <p className={c.text}>Delivered vehicles</p>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default AboutUs;