import { createContext, useContext, useState, useEffect } from 'react';
import Modal from '../components/modal';
import Form from '../components/form';
export const ModalContext = createContext(null);

export const useAppContext = () => useContext(ModalContext);

export const AppProvider = ({ children }) => {
  const [modal, setModal] = useState(null);

  const contextProps = {
    modal,
    setModal
  };

  useEffect(() => {
		if (modal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
	}, [modal]);

  return (
    <ModalContext.Provider value={contextProps}>
      {children}
      {modal && (
        <Modal toggleModal={(type) => setModal(type)}>
          <Form type={modal} />
        </Modal>
      )}
    </ModalContext.Provider>
  );
};
