const Arrow = ({ direction }) => { 
  const style = direction === 'up' ? {
    transform: 'rotate(180deg)',
  } : {
    transform: 'rotate(0deg)',
  };

  return (
    <svg
      style={{
        transition: 'all 0.2s ease-in-out',
        ...style,
      }}
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.06 16.7742C12.7788 17.0551 12.3975 17.2129 12 17.2129C11.6025 17.2129 11.2213 17.0551 10.94 16.7742L5.28202 11.1182C5.00076 10.8368 4.8428 10.4552 4.8429 10.0574C4.84299 9.65953 5.00113 9.27801 5.28252 8.99674C5.56392 8.71548 5.94552 8.55752 6.34338 8.55762C6.74123 8.55771 7.12276 8.71585 7.40402 8.99724L12 13.5932L16.596 8.99724C16.8788 8.72387 17.2576 8.5725 17.6509 8.57573C18.0442 8.57896 18.4205 8.73654 18.6988 9.01452C18.977 9.2925 19.1349 9.66865 19.1385 10.0619C19.1421 10.4552 18.9911 10.8342 18.718 11.1172L13.061 16.7752L13.06 16.7742Z"
        fill="#505050"
      />
    </svg>
  );
}

export default Arrow;