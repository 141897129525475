import Layout from "../layout";
import c from './style.module.scss';

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <footer className={c.footer}>
      <Layout>
        <p>
          © {year} Intercar Delivery
        </p>
      </Layout>
    </footer>
  )
}

export default Footer;