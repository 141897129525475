import React from 'react';
import Button from '../button';
import Layout from '../layout';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';
import c from './style.module.scss';

const DeliverVehicle = () => {
  const { setModal } = useContext(ModalContext);

  return (
    <div className={c.deliver}>
      <Layout>
        <div className={c.content}>
          <h1>
            Let Us Deliver Your Vehicle
            <br />
            <span>To Its Destination</span>
          </h1>
          <Button
            onClick={() => setModal("b")}
            text="Deliver the vehicle"
            variant="primary"
          />
        </div>
      </Layout>
    </div>
  );
};

export default DeliverVehicle;