import React from 'react';
import Layout from '../layout';
import Button from '../button';
import c from './style.module.scss';
import Picture from '../svg/picture';
import Support from '../svg/support';
import Protection from '../svg/protection';
import Documents from '../svg/documents';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';

const Logistics = () => { 
  const logisticsData = [
    {
      title: "Photo reporting",
      text: "We provide detailed photo reports for every shipment, ensuring transparency and peace of mind throughout the transport process",
      SVG: Picture,
    },
    {
      title: "24/7 support",
      text: "Available around the clock to assist with any inquiries and ensure seamless service delivery",
      SVG: Support,
    },
    {
      title: "Car storage at our site",
      text: "Secure, accessible storage solutions for your vehicles",
      SVG: Protection,
    },
    {
      title: "Issuance of freight and transport documents",
      text: "We handle all necessary documentation for freight and transport, ensuring compliance and ease of transit.",
      SVG: Documents,
    },
  ];

  const { setModal } = useContext(ModalContext);

  return (
    <div id="logistics" className={c.logistics}>
      <Layout>
        <div className={c.container}>
          <div className={c.left}>
            <p className={c.title}>Logistics Solutions</p>
            <p className={c.subtitle}>
              Explore Our Comprehensive Logisics Solutions
            </p>
            <Button onClick={() => setModal('a')} variant='secondary' text="Get more information" />
          </div>
          <div className={c.right}>
            {logisticsData.map(({title, text, SVG}) => {
              return (
                <div key={title} className={c.logisticItem}>
                  <div className={c.top}>
                    <SVG />
                    <p className={c.logisticTitle}>{title}</p>
                  </div>
                  <p className={c.logisticText}>{text}</p>
                </div>
              )
            })}
          </div>
        </div>
      </Layout>
    </div>
  ); 
};

export default Logistics;