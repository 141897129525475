import Header from './components/header';
import Heading from './components/heading';
import AboutUs from './components/about-us';
import Benefits from './components/benefits';
import Logistics from './components/logistics';
import Calculation from './components/calculation';
import Ports from './components/ports';
import FAQ from './components/faq';
import DeliverVehicle from './components/deliver-vehicle';
import Footer from './components/footer';
import { AppProvider } from './context/appContext';

function App() {
  return (
    <AppProvider >
      <div className="App">
        <Header />
        <main id="main">
          <Heading />
          <AboutUs />
          <Benefits />
          <Logistics />
          <Calculation />
          <Ports />
          <FAQ />
          <DeliverVehicle />
          <Footer />
        </main>
      </div>
    </AppProvider>
  );
}

export default App;
