import React from 'react';
import Layout from '../layout';
import c from './style.module.scss';

const Ports = () => {
  return (
    <div className={c.ports}>
      <Layout>
        <div className={c.container}>
          <div className={c.left}>
            <p className={c.heading}>Logistics ways</p>
            <p className={c.subHeading}>From which ports do we ship</p>
            <div className={c.text}>
              We ship from major ports across USA, Canada, ensuring efficient
              and strategic logistics for your transport needs
            </div>
            <div className={c.text}>
              New York, Chicago, Savannah, and Norfolk; Houston and Miami; Los
              Angeles and Seattle; Anchorage, as well as the Canadian port of
              Toronto.
            </div>
          </div>
          <img className={c.mobileImg} src="/images/ports_mobile.png" alt="ports" />
        </div>
      </Layout>
    </div>
  );
};

export default Ports;