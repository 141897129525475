const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2886 3.43934C18.8744 2.85355 19.8241 2.85355 20.4099 3.43934C20.9957 4.02513 20.9957 4.97487 20.4099 5.56066L14.046 11.9246L20.41 18.2886C20.9958 18.8744 20.9958 19.8241 20.41 20.4099C19.8242 20.9957 18.8745 20.9957 18.2887 20.4099L11.9247 14.0459L5.56066 20.4099C4.97487 20.9957 4.02513 20.9957 3.43934 20.4099C2.85355 19.8241 2.85355 18.8744 3.43934 18.2886L9.80336 11.9246L3.43946 5.56066C2.85368 4.97487 2.85368 4.02513 3.43946 3.43934C4.02525 2.85355 4.975 2.85355 5.56078 3.43934L11.9247 9.80324L18.2886 3.43934Z"
        fill="#FF6913"
      />
    </svg>
  );
}

export default Close;