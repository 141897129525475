const Support = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.8633 49C21.9146 49 21.0937 48.4317 20.6986 47.6048C12.4143 45.8497 5.81094 39.292 3.69783 30.8515H2.41552C1.08146 30.8515 0 29.7275 0 28.3411V21.7282C0 20.3418 1.08146 19.2178 2.41552 19.2178H3.69783C6.1777 9.31235 14.8418 2 25.1493 2C35.4567 2 44.1208 9.31235 46.6007 19.2178H47.5845C48.9185 19.2178 50 20.3418 50 21.7282V28.3411C50 29.7275 48.9185 30.8515 47.5845 30.8515H44.8501C44.4795 30.8515 44.1791 30.5393 44.1791 30.1542V20.6863C42.2645 11.5949 34.4716 4.78932 25.1493 4.78932C14.3906 4.78932 5.66898 13.8535 5.66898 25.0347C5.66898 34.6624 12.1355 42.7205 20.8009 44.7737C21.2253 44.0524 21.9902 43.571 22.8633 43.571H29.2263C30.5603 43.571 31.6418 44.6949 31.6418 46.0813V46.4896C31.6418 47.8761 30.5603 49 29.2263 49H22.8633ZM26.8273 30.5723L31.1735 20.8931H29.8267L25.4804 30.5723H26.8273ZM16.6259 25.2711C17.113 24.7945 17.3518 24.3776 17.3518 24.0103C17.3518 23.6926 17.0271 23.4345 16.6354 23.4345C16.1865 23.4345 15.8139 23.7621 15.6133 24.2783L14.0563 23.504C14.5435 22.4318 15.4987 21.7667 16.6354 21.7667C17.3232 21.7667 17.9059 21.9851 18.3835 22.412C18.8611 22.8388 19.0999 23.3451 19.0999 23.9507C19.0999 24.6953 18.6032 25.5192 17.6193 26.4226L16.8456 27.1672H19.1381V28.835H14.276V27.5444L16.6259 25.2711ZM24.5647 28.835H22.8931V27.6139H19.674V25.9461L22.96 21.8858H24.5647V25.956H25.2907V27.6139H24.5647V28.835ZM22.8931 25.956V24.4471L21.6704 25.956H22.8931ZM33.8453 28.835H31.9826L34.4185 23.4742H31.314V21.8858H36.2429V23.5735L33.8453 28.835Z"
        fill="#505050"
      />
    </svg>
  );
}

export default Support;