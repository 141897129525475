const Engine = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 4.5V6.5H10V8.5H7L5 10.5V13.5H3V10.5H1V18.5H3V15.5H5V18.5H8L10 20.5H18V16.5H20V19.5H23V9.5H20V12.5H18V8.5H12V6.5H15V4.5H7Z"
        fill="#FF6913"
      />
    </svg>
  );
}

export default Engine;