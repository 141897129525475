import React, { useState } from 'react';
import cn from 'classnames';
import Layout from '../layout';
import Button from '../button';
import Arrow from '../svg/arrow';
import { useContext } from 'react';
import { ModalContext } from '../../context/appContext';

import c from './style.module.scss';

const accordionData = [
  {
    question: 'How long does it take to deliver a car from America to Ukraine?',
    answer: `From the moment of purchasing a car at an auction in Canada or the USA to its arrival at the European port in Klaipeda, it takes about 4-8 weeks. The client receives the VIN code of the purchased vehicle, a CARFAX report, and a photo report consisting of 20 to 50 high-quality photographs of the car's actual condition.`
  },
  {
    question: 'How does delivery occur in the USA?',
    answer: '"Intercar Delivery" ensures the fastest possible delivery from the auction site to the shipping port. Delivery is made from any location in the USA, without intermediaries.'
  },
  {
    question: 'How container loading occurs',
    answer: 'At the loading area, cargo consolidation takes place (collecting shipments from different senders in one direction). After loading, the container is sent to the port, and the documents are sent to U.S. Customs.'
  },
  {
    question: 'Maritime Transportation',
    answer: '"Intercar Delivery" has direct agreements with the leader in liner shipping, Baltic Auto Shipping, ensuring timely fulfillment of all commitments to clients under the most favorable conditions for all parties.'
  },
  {
    question: 'Delivery Across Ukraine. Where Delivery is Available',
    answer: 'We can deliver the vehicle to any location in Ukraine by car carrier or tow truck'
  }
]

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const { setModal } = useContext(ModalContext);

  return (
    <div id="faq" className={c.faq}>
      <Layout>
        <div className={c.container}>
          <div className={c.left}>
            <p className={c.heading}>Frequently Asked Questions</p>
            <p className={c.text}>If You Have Any Questions Contact Us</p>
            <Button
              onClick={() => setModal("a")}
              variant="tetriary"
              text="Request a quote"
            />
          </div>
          <div className={c.right}>
            {accordionData.map(({ question, answer }, index) => {
              return (
                <div
                  onClick={() => {
                    if (index === activeIndex) {
                      setActiveIndex(null);
                      return;
                    }
                    setActiveIndex(index);
                  }}
                  className={c.acc_item}
                  key={question}
                >
                  <p className={c.question}>
                    {question}
                    <Arrow direction={index === activeIndex ? "up" : "down"} />
                  </p>
                  <p
                    className={cn(c.answer, {
                      [c.active]: activeIndex === index,
                    })}
                  >
                    {answer}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default FAQ;