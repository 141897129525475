const Protection = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2001 31.65L25.1505 31.6915C24.5226 32.2175 23.6908 32.6041 22.699 32.6041H22.6479C21.5824 32.6041 20.5967 32.1024 19.9553 31.3526L13.7681 24.464L19.2861 19.5333L22.9715 23.6441L31.2316 15.7506L36.2687 21.1848L25.2001 31.65ZM31.139 18.8218L33.2361 21.0843L23.7732 30.0314C23.4663 30.2885 23.1082 30.4428 22.699 30.4428H22.6479C22.2387 30.4428 21.8295 30.2371 21.5737 29.9285L16.8167 24.6323L19.1185 22.5755L22.8525 26.7405L31.139 18.8218ZM40.7531 12.3906C40.7545 13.2694 40.7624 14.184 40.8048 15.1373C40.9081 20.6206 40.9879 26.5976 39.0071 31.9668C37.1016 37.1318 33.2249 41.8774 25.0005 44.7874C16.752 41.8774 12.876 37.1205 10.9764 31.9502C9.00128 26.5746 9.09341 20.5986 9.19682 15.1374C9.23926 14.184 9.24712 13.2694 9.24857 12.3906C15.62 12.2763 20.7264 10.3739 25.0008 6.38073C29.2752 10.3739 34.3816 12.2763 40.7531 12.3906ZM26.075 4.42422C30.1159 8.38356 34.9753 10.2347 41.3692 10.2347C42.2387 10.2347 42.9037 10.9031 42.9037 11.7773V11.7775C42.9037 12.8572 42.9037 13.937 42.9549 15.0682C43.1595 25.9178 43.4152 40.6753 25.5123 46.8972L25.0008 47L24.4893 46.8972C6.56133 40.6844 6.84134 25.9093 7.04591 15.1152L7.0468 15.0682C7.09795 13.9369 7.09795 12.8571 7.09795 11.7773C7.09795 10.9031 7.76292 10.2347 8.63248 10.2347C15.0264 10.2347 19.8857 8.38356 23.9267 4.42422C24.5405 3.85859 25.4612 3.85859 26.075 4.42422Z"
        fill="#505050"
      />
    </svg>
  );
}

export default Protection;