import React from 'react';

const Documents = () => {
	return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.8853 14.7704H29.164C29.9245 14.7704 30.5411 15.3869 30.5411 16.1474C30.5411 16.9079 29.9245 17.5245 29.164 17.5245H9.8853C9.1248 17.5245 8.50825 16.9079 8.50825 16.1474C8.50825 15.3869 9.1248 14.7704 9.8853 14.7704Z"
        fill="#505050"
      />
      <path
        d="M23.6558 20.2786H9.8853C9.1248 20.2786 8.50825 20.8952 8.50825 21.6557C8.50825 22.4162 9.1248 23.0327 9.8853 23.0327H23.6558C24.4163 23.0327 25.0329 22.4162 25.0329 21.6557C25.0329 20.8952 24.4163 20.2786 23.6558 20.2786Z"
        fill="#505050"
      />
      <path
        d="M9.8853 25.7867H23.6558C24.4163 25.7867 25.0329 26.4032 25.0329 27.1637C25.0329 27.9242 24.4163 28.5408 23.6558 28.5408H9.8853C9.1248 28.5408 8.50825 27.9242 8.50825 27.1637C8.50825 26.4032 9.1248 25.7867 9.8853 25.7867Z"
        fill="#505050"
      />
      <path
        d="M23.6558 31.2951H9.8853C9.1248 31.2951 8.50825 31.9117 8.50825 32.6722C8.50825 33.4327 9.1248 34.0492 9.8853 34.0492H23.6558C24.4163 34.0492 25.0329 33.4327 25.0329 32.6722C25.0329 31.9117 24.4163 31.2951 23.6558 31.2951Z"
        fill="#505050"
      />
      <path
        d="M20.9017 39.7411H29.164C29.9245 39.7411 30.541 40.3576 30.541 41.1181C30.541 41.8786 29.9245 42.4952 29.164 42.4952H20.9017C20.1412 42.4952 19.5246 41.8786 19.5246 41.1181C19.5246 40.3576 20.1412 39.7411 20.9017 39.7411Z"
        fill="#505050"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.9462 15.7924C44.5574 14.1813 47.1783 14.1812 48.789 15.792C50.4039 17.4066 50.4035 20.0199 48.7889 21.6344L38.8033 31.6284V43.8722C38.8033 46.1501 36.9501 48.0033 34.6722 48.0033H7.13115C4.85323 48.0033 3 46.1501 3 43.8722V5.13115C3 2.85323 4.85323 1 7.13115 1H30.7773C31.8808 1 32.9183 1.42964 33.6985 2.20997L37.5933 6.10491C38.3736 6.88515 38.8033 7.92252 38.8033 9.026V19.9388L42.9462 15.7924ZM30.6067 3.75353C30.5835 3.75382 30.5615 3.7541 30.5411 3.7541V9.2623H36.0493L36.0496 9.20341C36.0517 8.90697 36.0548 8.46132 35.6459 8.05233L31.7511 4.15739C31.3378 3.74425 30.8797 3.75006 30.6067 3.75353ZM34.6722 45.2492C35.4316 45.2492 36.0492 44.6315 36.0492 43.8722V34.3834L35.8821 34.5505C35.731 34.7017 35.5467 34.8156 35.3439 34.8831L29.5015 36.8306C29.0074 36.9954 28.4617 36.8672 28.0923 36.4979C27.7235 36.1291 27.5947 35.5835 27.7596 35.0887L29.7072 29.2463C29.7747 29.0435 29.8887 28.8592 30.0399 28.7081L36.0492 22.6952V12.0164H29.164C28.4035 12.0164 27.7869 11.3999 27.7869 10.6394V3.7541H7.13115C6.37185 3.7541 5.7541 4.37185 5.7541 5.13115V43.8722C5.7541 44.6315 6.37185 45.2492 7.13115 45.2492H34.6722ZM34.9084 31.6293L32.9609 29.6818L32.2171 30.4256L31.2434 33.3468L34.1645 32.3732L34.9084 31.6293ZM34.908 27.734L36.8554 29.6814L43.4749 23.0563L41.5275 21.1089L34.908 27.734ZM45.4216 21.1081L46.8412 19.6873C47.3813 19.147 47.3785 18.2765 46.8417 17.7394C46.3048 17.2026 45.4314 17.2025 44.8942 17.7394L43.4742 19.1606L45.4216 21.1081Z"
        fill="#505050"
      />
    </svg>
  );
}

export default Documents;