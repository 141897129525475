import React from 'react';

const Call = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8313 22.0916C16.9878 22.0916 15.8029 21.7866 14.0287 20.7955C11.8711 19.5858 10.2023 18.469 8.0564 16.3292C5.98742 14.2619 4.98059 12.9234 3.57146 10.3598C1.97955 7.46514 2.25092 5.94784 2.55426 5.29936C2.91551 4.52429 3.44874 4.06072 4.13797 3.6006C4.52944 3.34416 4.94372 3.12434 5.37555 2.94391C5.41876 2.92534 5.45895 2.90762 5.49481 2.89164C5.70871 2.79529 6.0328 2.6497 6.44331 2.80523C6.71727 2.90805 6.96185 3.11845 7.3447 3.49648C8.12986 4.27069 9.2028 5.99493 9.59862 6.84171C9.86437 7.41243 10.0402 7.78916 10.0407 8.21169C10.0407 8.70637 9.79177 9.08785 9.48973 9.49958C9.43312 9.57691 9.37694 9.65079 9.3225 9.72251C8.99366 10.1545 8.92149 10.2794 8.96903 10.5023C9.06539 10.9503 9.784 12.284 10.965 13.4622C12.1459 14.6403 13.4414 15.3134 13.8913 15.4094C14.1237 15.459 14.2512 15.3839 14.6972 15.0434C14.7611 14.9946 14.8268 14.9441 14.8955 14.8935C15.3561 14.5509 15.72 14.3085 16.2031 14.3085H16.2057C16.6261 14.3085 16.9861 14.4909 17.5824 14.7915C18.3602 15.1838 20.1366 16.2427 20.9157 17.0286C21.2947 17.4105 21.506 17.6542 21.6093 17.9277C21.7648 18.3394 21.6184 18.6621 21.5229 18.8781C21.5069 18.914 21.4892 18.9533 21.4706 18.997C21.2887 19.4279 21.0675 19.8413 20.8099 20.2317C20.3505 20.9186 19.8851 21.4505 19.1082 21.8121C18.7092 22.0008 18.2726 22.0964 17.8313 22.0916Z"
        fill="#FF6913"
      />
    </svg>
  );
};

export default Call;