import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Logo from '../svg/logo';	
import Call from '../svg/call';
import Hamburger from '../svg/hamburger';
import Layout from '../layout';
import Button from '../button';
import c from './style.module.scss'

const Header = () => {
	const [isOpen, setIsOpen] = useState(false);
	const navItems = [
		{text: 'About Us', href: '/#about'},
		{text: 'Benefits', href: '/#benefits'},
		{text: 'Logistics Solutions', href: '/#logistics'},
		{text: 'Calculator', href: '/#calculation'},
		{text: 'FAQ’s', href: '/#faq'},
	];

	useEffect(() => {
		const main = document.getElementById('main');

		if(isOpen) {
			main.style.filter = 'blur(5px)';
			document.body.style.overflow = 'hidden';
		}else {
			document.body.style.overflow = 'unset';
			main.style.filter = 'unset';
		}
	}, [isOpen]);

	return (
    <div className={c.topHeader}>
      <Layout>
        <div className={c.wrapper}>
          <header className={c.header}>
            <div className={c.logo}>
              <Logo />
            </div>
            <div className={c.mobileNav}>
              <a href="mailto:contact@intercardelivery.com">
                <Call />
              </a>
              <Hamburger isOpen={isOpen} setIsOpen={setIsOpen} />
            </div>
            <ul className={c.desktopNav}>
              {navItems.map(({ text, href }) => {
                return (
                  <li key={text}>
                    <a href={href}>{text}</a>
                  </li>
                );
              })}
            </ul>
            <a className={c.btn} href="mailto:contact@intercardelivery.com">
              <Button text="Contact Us" />
            </a>
          </header>
            <ul className={cn(c.overlay, {
							[c.isOpen]: isOpen,
						})}>
              {navItems.map(({ text, href }) => {
                return (
                  <li key={text} onClick={()=> setIsOpen(false)}>
                    <a href={href}>{text}</a>
                  </li>
                );
              })}
            </ul>
        </div>
      </Layout>
    </div>
  );
};

export default Header;