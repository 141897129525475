const ArrowDirection = () => { 
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7688 4.16878C14.0689 3.86882 14.4758 3.70032 14.9 3.70032C15.3243 3.70032 15.7312 3.86882 16.0312 4.16878L23.2312 11.3688C23.5312 11.6688 23.6997 12.0757 23.6997 12.5C23.6997 12.9242 23.5312 13.3311 23.2312 13.6312L16.0312 20.8312C15.7295 21.1226 15.3253 21.2839 14.9058 21.2803C14.4863 21.2766 14.085 21.1083 13.7883 20.8117C13.4917 20.515 13.3234 20.1137 13.3198 19.6942C13.3161 19.2747 13.4774 18.8705 13.7688 18.5688L18.1 14.1H2.90005C2.4757 14.1 2.06874 13.9314 1.76868 13.6313C1.46862 13.3313 1.30005 12.9243 1.30005 12.5C1.30005 12.0756 1.46862 11.6687 1.76868 11.3686C2.06874 11.0685 2.4757 10.9 2.90005 10.9H18.1L13.7688 6.43118C13.4689 6.13113 13.3004 5.72424 13.3004 5.29998C13.3004 4.87571 13.4689 4.46882 13.7688 4.16878Z"
        fill="white"
      />
    </svg>
  );
}

export default ArrowDirection;