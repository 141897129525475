const Location = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 2.5C14.3869 2.5 16.6761 3.44821 18.364 5.13604C20.0518 6.82387 21 9.11305 21 11.5C21 14.574 19.324 17.09 17.558 18.895C16.6755 19.7869 15.7128 20.5956 14.682 21.311L14.256 21.601L14.056 21.734L13.679 21.974L13.343 22.179L12.927 22.421C12.6445 22.5818 12.325 22.6663 12 22.6663C11.675 22.6663 11.3555 22.5818 11.073 22.421L10.657 22.179L10.137 21.859L9.945 21.734L9.535 21.461C8.42298 20.7083 7.38707 19.8489 6.442 18.895C4.676 17.089 3 14.574 3 11.5C3 9.11305 3.94821 6.82387 5.63604 5.13604C7.32387 3.44821 9.61305 2.5 12 2.5ZM12 4.5C10.1435 4.5 8.36301 5.2375 7.05025 6.55025C5.7375 7.86301 5 9.64348 5 11.5C5 13.822 6.272 15.86 7.871 17.496C8.55862 18.1919 9.30174 18.8307 10.093 19.406L10.551 19.732C10.699 19.835 10.841 19.931 10.978 20.02L11.368 20.27L11.711 20.479L12 20.648L12.455 20.379L12.822 20.149C13.017 20.025 13.227 19.886 13.449 19.732L13.907 19.406C14.6983 18.8307 15.4414 18.1919 16.129 17.496C17.728 15.861 19 13.822 19 11.5C19 9.64348 18.2625 7.86301 16.9497 6.55025C15.637 5.2375 13.8565 4.5 12 4.5ZM12 7.5C13.0609 7.5 14.0783 7.92143 14.8284 8.67157C15.5786 9.42172 16 10.4391 16 11.5C16 12.5609 15.5786 13.5783 14.8284 14.3284C14.0783 15.0786 13.0609 15.5 12 15.5C10.9391 15.5 9.92172 15.0786 9.17157 14.3284C8.42143 13.5783 8 12.5609 8 11.5C8 10.4391 8.42143 9.42172 9.17157 8.67157C9.92172 7.92143 10.9391 7.5 12 7.5ZM12 9.5C11.4696 9.5 10.9609 9.71071 10.5858 10.0858C10.2107 10.4609 10 10.9696 10 11.5C10 12.0304 10.2107 12.5391 10.5858 12.9142C10.9609 13.2893 11.4696 13.5 12 13.5C12.5304 13.5 13.0391 13.2893 13.4142 12.9142C13.7893 12.5391 14 12.0304 14 11.5C14 10.9696 13.7893 10.4609 13.4142 10.0858C13.0391 9.71071 12.5304 9.5 12 9.5Z"
        fill="#505050"
      />
    </svg>
  );
};

export default Location;