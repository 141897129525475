import React from 'react';
import c from './style.module.scss';

const Modal = ({ children, toggleModal }) => { 
  return (
    <>
      <div onClick={() => toggleModal(null)} className={c.modal} />
      <div className={c.children}>
       {children}
      </div>
    </>
  ); 
};

export default Modal;