import React from 'react';
import cn from 'classnames';
import c from './style.module.scss';

const Layout = ({children, className = '', innerClassName = ''}) => {
	return (
		<div className={cn(c.container, className)}>
			<div className={cn(c.inner, innerClassName)}>
				{children}
			</div>
		</div>
	)
}

export default Layout;