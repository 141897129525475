const Currency = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 15.5H9C9 16.58 10.37 17.5 12 17.5C13.63 17.5 15 16.58 15 15.5C15 14.4 13.96 14 11.76 13.47C9.64 12.94 7 12.28 7 9.5C7 7.71 8.47 6.19 10.5 5.68V3.5H13.5V5.68C15.53 6.19 17 7.71 17 9.5H15C15 8.42 13.63 7.5 12 7.5C10.37 7.5 9 8.42 9 9.5C9 10.6 10.04 11 12.24 11.53C14.36 12.06 17 12.72 17 15.5C17 17.29 15.53 18.81 13.5 19.32V21.5H10.5V19.32C8.47 18.81 7 17.29 7 15.5Z"
        fill="#FF6913"
      />
    </svg>
  );
}

export default Currency;